<template>
  <div id="app_question_div">
    <div class="camera_div" v-if="cameraShow">
      <vehicleCamera
        @photoSubmit="cameraSave"
        :key="cameraKey"
        :sideStatus="sideStatus"
        @cancel="cameraCancel"
      ></vehicleCamera>
    </div>
    <van-form @submit="onSubmit" >
      <div class="upload_img">
        <van-tabs v-model="tabsActive">
        <van-tab title="上传行驶证">
          <div v-if="tabsActive == 0">
            <van-field
              name="uploader"
              v-model="model.frontLicensePhoto"
              :rules="[{ required: true, message: '请上传行驶证主页' }]" style="width:50%;">
              <template #input>
                <div class="license" :style='{backgroundImage:"url("+$cosImg(model.frontLicensePhoto)+")"}' @click="type = 0;showCamera()">
                  <img src="../../assets/image/photo.png"/>
                    <label >扫描行驶证主页</label>
                </div>
              </template>
            </van-field>
            <van-field
              name="uploader"
              v-model="model.backLicensePhoto"
              :rules="[{ required: true, message: '请上传行驶证副页' }]" style="width:50%;margin-left:48%;margin-top:-7.5rem;">
              <template #input>
                <div class="license" :style='{backgroundImage:"url("+$cosImg(model.backLicensePhoto)+")"}' @click="type = 1;showCamera()">
                  <img src="../../assets/image/photo.png" />
                  <label>扫描行驶证副页</label>
                    
                </div>
              </template>
            </van-field>
        </div>
        </van-tab>
        <van-tab title="手动添加"> </van-tab>
        <plateNumber  @getPlateLicense="getPlateLicense" :showAdd='showBatch' ref="plate"></plateNumber>
      </van-tabs>
      </div>
      <div class="middle_top"></div>
      <van-field placeholder="请输入顾客姓名" v-model="model.name" label="车主姓名" :rules="[{ required: true, message: '请填写姓名' }]"/>
      <van-field maxlength="11" label="车主电话"
        placeholder="请输入车主电话" 
        type="tel"
        v-model="model.mobile"
        :rules="[
          { required: true, message: '请填写车主电话' },
          { validator: thatUtil.checkPhone, message: '请输入正确的手机号' },
        ]"
      />
      <van-field maxlength="11" label="联系电话"
        placeholder="请输入联系电话" 
        type="tel"
        v-model="model.concatPhone"
        :rules="[
          { required: true, message: '请填写联系电话' },
          { validator: thatUtil.checkPhone, message: '请输入正确的手机号' },
        ]"
      />
        <van-field label="所在地区"
          v-model="fieldValue"
          :rules="[{ required: true, message: '请选择所在地区' }]"
          is-link
          readonly
          placeholder="请选择所在地区"
          @click="address_show = true"
        />
      <PosiTionBtn @position='getlocationCity'></PosiTionBtn>
      <van-popup v-model="address_show" round position="bottom">
        <van-cascader
          v-model="cascaderValue"
          title="请选择所在地区"
          :options="addr_options"
          :field-names="{ text: 'name', value: 'id', children: 'children' }"
          @close="address_show = false"
          @change="onAddrChange"
          @finish="onAddrFinish"
        />
      </van-popup>
      <!-- <div :style="{width:'100%',display: 'inline-block'}">
        <van-field  label="详细地址" v-model="model.detailAddress"  placeholder="请输入详细地址" />
      </div> -->
      
      <div class="middle_top" ></div>
      <van-field  label="品牌车型" v-model="model.model"  placeholder="请输入品牌车型" />

      <van-field label="车辆类型"
        readonly
        clickable
        name="scrapType"
        :rules="[{ required: true, message: '请选择车辆类型' }]"
        v-model="action_text.scrapType"
        placeholder="点击选择车辆类型"
        @click="actionsInit(1)"
      />
      <van-field  label="店铺ID"
        :rules="[{ required: true, message: '请填写店铺ID' }]"
        v-if="showStore" v-model="model.storeId" type="digit" placeholder="请输入店铺ID" />
      <div class="footer">
        <van-button round block type="info" native-type="submit" style="background-color: #4600af;border: 1px solid #4600af;">
          {{isCustomer?'自助估价':'马上提交'}}
        </van-button>
      </div>
    </van-form>
    <van-action-sheet
      v-model="show"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @select="onSelect"
    />
    <van-dialog
      v-model="name_show"
      title="提示"
      :show-cancel-button="false"
      :showConfirmButton="false"
    >
      <div style="padding: 10px">
        <label>请输入您的姓名</label>
        <van-field v-model="model.ProviderName" placeholder="请输入您的姓名" />
        <div style="padding: 10px">
          <van-button block @click="nameBeforeClose" type="primary"
            >确定</van-button>
        </div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import plateNumber from "../../components/plateNumber";
import vehicleCamera from "../../components/vehicleCamera.vue";
import Cookies from "js-cookie";
import wx from 'weixin-js-sdk';
import PosiTionBtn from '../../components/addressComponent.vue'
import {userInfo,providerInfo } from '../../store/provider'
import {allinpayUser} from '../../store/allinpay'
import util from '../../helper/util'
import {getStandardAddressList} from '../../api/common'
import {getWXinfo} from '../../api/wxAuth'
import {batchCollection,vehicleTypeGetList,OCRDriverLicense} from '../../api/provider'
import {
  Dialog,
  Uploader,
  Button,
  Cell,
  CellGroup,
  Field,
  Form,
  Cascader,
  RadioGroup,
  Radio,
  Overlay,
  Loading,
  Popup,
  ActionSheet,
  Icon,
  Tab,
  Tabs,
  IndexBar,
  Popover,
  IndexAnchor,
  NavBar,
  Step,
  Steps,
  Empty,
  Search,
} from "vant";
export default {
  name: "upload",
  components: {
    PosiTionBtn,
    plateNumber,
    vehicleCamera,
    [Icon.name]: Icon,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Empty.name]: Empty,
    [Search.name]: Search,    
    [Uploader.name]: Uploader,
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Form.name]: Form,
    [NavBar.name]: NavBar,
    [Cascader.name]: Cascader,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
    [Popup.name]: Popup,
    [ActionSheet.name]: ActionSheet,
    [IndexBar.name]: IndexBar,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Popover.name]: Popover,
    [IndexAnchor.name]: IndexAnchor,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      thatUtil:{},
      step_active: 0,
      search_word:'',
      showPopover: false,
      sideStatus: false,
      tabsActive: 0,
      cameraShow: false,
      type: 0,
      isRequestWeight: true,
      position_loading: false,
      confirmButtonText: "下一题",
      question_show: false,
      submit_show: false,
      id: this.$route.query.id,
      canDriveRadio: "",
      fieldValue: "",
      modelValue: [],
      addr_options: [],
      brand_options: [],
      series_options: [],
      model_options: [],
      cascaderValue: "",
      address_show: false,
      brand_show: false,
      hasViolationRadio: "",
      show: false,
      current_index: "",
      fileList: [],
      fileListTwo: [],
      actions: [],
      total_price: 0,
      brandName: "",
      action_text: {
        mileAge: "",
        scrapType: "",
      },
      model: {
        concatPhone:"",
        name: "",
        providerId: "",
        mobile: "",
        mileAge: "",
        address: "",
        detailAddress: "",
        province: 0,
        city: 0,
        country: 0,
        licensePlate: "",
        model: "",
        scrapType: "",
        canDrive: "",
        hasViolation: "",
        frontLicensePhoto: "",
        autoWeight: "",
        cQuestionJson: "",
        SouceType: 1,
        ProviderName: "",
        backLicensePhoto: "",
        identifyLicense: "",
        brandId: "",
        seriesId: "",
        modelId: "",
        licenseVehicleTypeName:'',
        engineNum:'',
        VIN:'',
        items:[]
      },
      batchForm:{},
      cameraKey: 0,
      car_res: "",
      name_show: false,
      locationForm: {
        latitude: "",
        longitude: "",
      },
      isCustomer: false,
      //要忽略的部门id
      ignoreDeptId:[0,1],
      wxConfigStatus:false
    };
  },
  methods: {
    showCamera(){
      if(!this.thatUtil.isWeiXin()){
        this.sideStatus = false;
        this.cameraKey += 1;
        this.cameraShow = false;
        this.cameraShow = true
        return
      }
      if(this.wxConfigStatus){
        this.getImage()
        return
      }
      this.$toast.loading({duration: 0,forbidClick: true,message: '初始化相机中',});
      var that = this;
      getWXinfo({url:window.location.href.split('#')[0]}).then(res=>{
          var data = res&&res.data;
          var config = {
              debug: false, 
              appId: data.appId, // 必填，公众号的唯一标识
              timestamp: data.timestamp, // 必填，生成签名的时间戳
              nonceStr: data.nonceStr, // 必填，生成签名的随机串
              signature: data.signature,// 必填，签名
              jsApiList: [
                  'checkJsApi',
                  'openLocation',
                  'chooseImage',
                  'getLocalImgData'] // 必填，需要使用的JS接口列表
          };
          wx.config(config);
          wx.ready(function(){
              that.$toast.clear();
              that.wxConfigStatus =  true
              that.getImage();
          });
          wx.error(function(res){
              that.$toast.clear();
              that.wxConfigErroeFlag = true;
              alert("发生错误,已切换到H5拍照："+JSON.stringify(res));
          });
      }).catch(err=>{
        console.log(err)
        this.$toast.clear();
        this.$toast.fail('切换到H5拍照')
      })
    },
    getImage(){
      var that = this;
      wx.chooseImage({
          count: 1, 
          sizeType: ['original'], 
          sourceType:  ['album', 'camera'],
          success: function (res) {
              var localIds_val = res.localIds[0]; 
              wx.getLocalImgData({
                  localId: localIds_val.toString(),//图片的本地ID
                  success: function (res) {
                      var localData = res.localData;//base64
                      if (localData.indexOf('data:image') != 0) {
                          localData = 'data:image/jpeg;base64,' +  localData
                      }
                      localData = localData.replace(/\r|\n/g, '').replace('data:image/jgp', 'data:image/jpeg')
                      that.cameraSave(localData);
                  }
              });
          }
      });
    },
    cancelBrandNav() {
      this.step_active = 0;
      this.brand_show = false;
      this.model.model = "";
      this.modelValue = [];
      this.model.brandId = "";
      this.model.seriesId = "";
      this.model.modelId = "";
    },
    getlocationCity(data,fieldValue) {
      if (data) {
        this.model.province = data.provinceId;
        this.model.city = data.cityId;
        this.model.country = data.countryId;
        this.fieldValue =fieldValue
        this.initVehicleTypeOptions();
      } else {
        this.$toast("定位失败，请手动选择");
      }
    },
    nameBeforeClose() {
      if (!this.model.ProviderName) {
        this.$toast.fail("请输入您的姓名！");
      } else {
        Cookies.set("ProviderName", this.model.ProviderName);
        this.name_show = false;
      }
    },
    onBeforeClose() {},
    onSubmit() {      
      if(this.model.items.length>0){
        var i = this.model.items.findIndex(e=>e.LicensePlate.length<7);
        if(i>-1){
          this.$toast.fail({ message: "请输入正确的车牌", position: "top" });
          return;
        }
      }
      if (this.model.items.length==0&&this.model.licensePlate.length < 7) {
        this.$toast.fail({ message: "请输入正确的车牌", position: "top" });
        return;
      }
      this.brandName = this.model.model;
      //如果是加盟商提交
      var provider  = providerInfo.get();
      if(provider&&this.ignoreDeptId.indexOf(provider.departmentId)>-1){
        this.model.storeId = provider.id;
        // this.model.providerId = provider.id;
      }
      if(this.model.items&&this.model.items.length>0){
        this.$toast.loading({duration: 0,forbidClick: true,message: '提交中',});
        batchCollection(this.model).then(res=>{
          this.$toast.clear();
          if(res.status){
            this.resetModel();
          }
          this.$toast({type:res.status?'success':'fail',message:res.message});
        }).catch(err=>{
          console.log(err)
          this.$toast.clear();
          this.$toast.fail("服务器错误，请联系客服");
        })
      }else{
        window.sessionStorage.setItem("uploadOrder_Model",JSON.stringify(this.model));
        var pid = this.$route.query.providerId;
        var query = {isCustomer:this.isCustomer};
        if(pid&&pid>0){
          query.providerId = pid;
        }
        this.$router.push({path:"/question",query:query});
      }
    },
    resetModel() {
      this.canDriveRadio = "";
      this.hasViolationRadio = "";
      this.action_text.scrapType = "";
      this.action_text.mileAge = "";
      this.fileList = [];
      this.fileListTwo = [];
      this.fieldValue = "";
      this.$refs.plate.clear();
      this.model = {
        name: "",
        mobile: "",
        mileAge: "",
        province: 0,
        city: 0,
        country: 0,
        licensePlate: "",
        model: "",
        scrapType: "",
        canDrive: "",
        hasViolation: "",
        aboutImg: "",
        licenseplate: "",
      };
    },
    fileDelete() {
      this.fileList = [];
      this.model.frontLicensePhoto = "";
    },
    fileDeleteTwo() {
      this.fileListTwo = [];
      this.model.backLicensePhoto = "";
    },
    getPlateLicense(data) {
      if(data.length>1){
        data = data.map((a)=>{ return {LicensePlate:a}})
        this.model.items = data;
      }else{
        this.model.items = [];
        this.model.licensePlate = data[0];
      }
      console.log(data);
    },
    onAddrChange({ value, selectedOptions, tabIndex }) {
      console.log(tabIndex)
      this.initCity(value, selectedOptions);
    },
    onAddrFinish({ selectedOptions }) {
      //console.log(selectedOptions);
      this.address_show = false;
      this.addr_options = [];
      this.initCity();
      this.fieldValue = selectedOptions.map((option) => option.name).join("/");
      var arr = selectedOptions.map((option) => option.id);
      this.model.province = arr && arr[0];
      this.model.city = arr && arr[1];
      this.model.country = arr && arr[2];
      this.initVehicleTypeOptions();
    },
    initVehicleTypeOptions(){
        this.actions = [];
        this.model.vehicleType = "";
        this.action_text.scrapType = "";
        vehicleTypeGetList({
            Province: this.model.province,
            City: this.model.city,
          }).then((res) => {
          this.actions = [];
            res.content.forEach((e) => {
                this.actions.push({ id: e.id, name: e.name });
            });
        });
    },
    onSelect(item) {
      switch (this.current_index) {
        case 0:
          this.model.mileAge = item.id;
          this.action_text.mileAge = item.name;
          break;
        case 1:
          this.model.vehicleType = item.id;
          this.action_text.scrapType = item.name;
          break;
      }
    },
    goStep(index) {
      this.step_active = index;
    },
    actionsInit(type) {
      this.show = true;
      this.current_index = type;
    },
    initCity(id, selectedOptions) {
      var body = { id: id };
      getStandardAddressList(body).then((res) => {
        res.forEach((e) => {
          if (e.isParent) {
            e.children = [];
          }
        });
        if (this.addr_options.length == 0 || id == 0) {
          this.addr_options = res;
        } else {
          if (selectedOptions && selectedOptions.length != 0) {
            selectedOptions[selectedOptions.length - 1].children = res;
          }
        }
      });
    },  
    afterRead(ext, imgdata) {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: "识别中，请稍等",
      });
      this.thatUtil.uploadFile(ext, imgdata, (err, data) => {
        if(!data){
          this.$toast.clear();
          this.$toast.fail("上传失败，请重试")
          return
        }
        var filename = data.Location.substr(data.Location.lastIndexOf("/") + 1);
        this.model.frontLicensePhoto = this.$global.BaseImagePath + filename;
        var imgUrl = this.$cosImg(this.model.frontLicensePhoto);
        OCRDriverLicense({ imgUrl: imgUrl, configure: "face" })
          .then((res) => {
            this.$toast.clear();
            if (!this.thatUtil.isJSON(res.data)||!res.data.plate_num) {
              this.$toast.fail("行驶证主页识别失败，请参考样例规范上传");
              this.fileDelete();
              return;
            }
            var certData = res.data;
            this.model.licensePlate = certData.plate_num;
            //this.model.model = certData.model;
            this.model.name = certData.owner;
            this.model.licenseVehicleTypeName = certData.vehicle_type,
            this.model.engineNum = certData.engine_num;
            this.model.VIN = certData.vin;
            this.setPlateNumber(certData.plate_num);
          }).catch((res) => {
            console.log(res)
            this.$toast.clear();
            this.fileDelete();
            this.$toast.fail("识别失败，请重试");
          });
      });
    },
    setPlateNumber(plate_num) {
      this.model.licensePlate = plate_num;
      this.$refs.plate.setPlateNumber(plate_num);
    },
    afterReadTwo(ext, imgdata) {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: "识别中，请稍等",
      });
      this.thatUtil.uploadFile(ext, imgdata, (err, data) => {
        if(!data){
          this.$toast.clear();
          this.$toast.fail("上传失败，请重试")
          return
        }
        var filename = data.Location.substr(data.Location.lastIndexOf("/") + 1);
        this.model.backLicensePhoto = this.$global.BaseImagePath + filename;
        var imgUrl = this.$cosImg(this.model.backLicensePhoto);
        OCRDriverLicense({ imgUrl: imgUrl, configure: "back" })
          .then((res) => {
            this.$toast.clear();
            if (!this.thatUtil.isJSON(res.data)||!res.data.plate_num) {
              this.$toast.fail("行驶证副页识别失败，请参考样例规范上传");
              this.fileDeleteTwo();
              return;
            }
            var certData = res.data;
            this.model.autoWeight = certData.unladen_mass;
          })
          .catch((res) => {
            console.log(res)
            this.$toast.clear();
            this.fileDeleteTwo();
            this.$toast.fail("识别失败，请重试");
          });
      });
    },
    cameraSave(imgData) {
      this.cameraShow = false;
      //this.$toast.loading({duration: 0,forbidClick: true,message: JSON.stringify(imgData)});
      if (!imgData) {
        this.$toast.fail("扫描失败!");
        return;
      }
      if (this.type == 0) {
        this.afterRead(".jpeg", imgData);
      } else {
        this.afterReadTwo(".jpeg", imgData);
      }
    },
    cameraCancel() {
      this.cameraShow = false;
    },
  },
  props:{
    customer:{
      type:String,
      default:''
    },
    showBatch:{
      type:Boolean,
      default:false
    },
    showStore:{
      type:Boolean,
      default:false
    }
  },
  mounted() {
    this.initCity();
    this.thatUtil = util;
    this.model.providerId = parseInt(this.$route.query.providerId || "0");
    if (this.model.providerId) {
      this.name_show = true;
      this.model.ProviderName = Cookies.get("ProviderName") || "";
    }
    //扫码进入的提交线索界面
    //|| this.model.providerId    
    debugger
    if(this.model.providerId){
      return
    }
    if (this.$route.name == "customerOrder" || this.customer ) {
      this.isCustomer = true;
      return
    }
    var allinUser =  allinpayUser.get();
    if(allinUser && allinUser.userSource==1){
      var user = userInfo.get();
      this.model.concatPhone = user.mobile;
    }else{
      this.isCustomer = true
    }
  },
  created() {
    
  },
};
</script>
<style scoped>
@import './uploadOrder.css';
</style>