<template>
<div class="uploadFacePic"> 
  <!-- 1 原始相机 不兼容时的回退方案-->
  <div style="display:none;">
    <input id="file" type="file" ref='camerafile' accept="image/*" @change="fileOnChange" >
    <input id="cameraFile" ref='photoFile' type="file" @change="photoChange" >
  </div>
  <!-- 2 自定义相机 -->
  <div style="width: 100%; position: fixed;z-index:10; left: 0; bottom: 0; top: 0; right: 0;" v-if="cameraShow">
    <!-- 顶部样式 -->
    <!-- <div style="width: 100%; position: fixed; left: 0; bottom: 90vh; top: 0; right: 0;background:black">  
    </div> -->
    <!-- 中间部分 -->
    <div ref="videoDiv" style="height: 75vh;width: 100vw;">
      <!-- style="height: 100%;width: 100%;position: fixed;left: 0;object-fit: fill;" -->
        <video style="height: 100%;width: 100%;object-fit: fill;"></video>
    </div>
    <div style="width: 100%;height:75vh;position: fixed; left: 0;z-index:10;bottom: 25vh; right: 0;text-align:center;">
      <!-- a.拍摄时展示取景框。把取景框图片换下就可以了 -->
      <img src="../assets/image/qujing.png" alt="" v-if="status==1&&!sideStatus" class="mask">
      <img src="../assets/image/qujing2.png" alt="" v-if="status==1&&sideStatus" class="mask">
      <!-- b.拍摄完后展示抓拍图片 -->
      <img :src="imageUrl" alt="" v-if="status==2" style="height:100%;z-index:10">
    </div>
    <!-- 底下控制部分-->
    <div class="control">
      <!-- 拍照前 -->
      <div class="control_before" v-if="status==1">
        <div class="control_before_top">
            拍摄行驶证<font>{{!sideStatus?'主页':'副页'}}</font>
        </div>
        <div class="control_before_bottom">
          <div class="smaller" @click="cameraCancel"><img style="width:30px;height:30px;" src="../assets/image/cancel.png"/><br/>取消</div>
          <!-- <i class="iconfont icon-xiangji bigger" @click="snapPhoto"></i> -->
          <div @click="snapPhoto"><img src="../assets/image/camera.png"/><br/></div>
          <!-- <i class="iconfont icon-zhongxin small" @click="front = !front"></i> -->
          <div @click="showPhotos"><img style="width:30px;height:30px;" src="../assets/image/photo_line.png"/>
            <br/>相册</div>
        </div>
      </div>
      <!-- 拍照后 -->
      <div class="control_after" v-if="status==2">
        <div class="smaller" @click="status=1">重拍</div>
        <div class="smaller" @click="submitPhoto('custom')">使用照片</div>
      </div>
    </div>
    <!-- 抓拍 -->
    <canvas id="mycanvas"></canvas>
  </div>
  <!-- 提示部分 -->
  <div class="tipinfo" v-if="tipVisible">
    <div class="successContent" v-if="tipInfo.result=='ok'">
      <van-icon name="passed"/>
      <div class="title">采集成功</div>
      <div class="info">恭喜您，采集成功</div>
      <div class="btn" @click="cameraCancel">{{'返回'+btntext}}</div>
    </div>
    <div class="failContent" v-else>
      <van-icon name="warning-o" />
      <div class="title">采集失败</div>
      <div class="info">{{tipInfo.msg+',请重拍'}}</div>
      <div class="btn" @click="cameraCancel">{{'返回'+btntext}}</div>
    </div>
  </div>
</div>
</template>
<script>
// import VConsole from 'vconsole/dist/vconsole.min.js'
export default ({
    data() {
        return {
            CameraVisable:false,
            type:'',//上传类型 update|upload
            cameraShow:false,//启动自定义相机
            status:0,//自定义相机-拍摄进度:0|未开启 1|开启但未拍摄 2|开启且已拍摄
            imageUrl:'',//自定义相机-抓拍url
            front:false,// 自定义相机-前置与后置转换（未验证）
            //提示部分
            tipVisible:false,
            tipInfo:{
            result:'fail',
            msg:'采集人脸失败'
            },//上传结果
            btntext:'',//倒计时文本
            time:null,//计时器
            imageFile:'',//图片对象
            imgBase64Data:'',
            vConsole:'',
        };
    },
    props:{
      sideStatus:{
        type:Boolean,
        default:false
      }
    },
    methods:{
      showPhotos(){
        document.getElementById('cameraFile').click();
      },
        openCamera() {
            try {
                this.CameraVisable = true;
               // 1. 先展示，因为要从这里获取video标签
                this.cameraShow=true
                var cheight = window.screen.availHeight*0.75;
                // 2. constraints:指定请求的媒体类型和相对应的参数
                var constraints={
                    audio: false,
                    video: {
                      facingMode: (this.front? "user" : "environment"),
                      // width:cwidth,
                      //设置媒体分辨率，设置了宽在移动端会显示不正确，所以只设置高度
                       height:{ideal:cheight}
                    }
                }
                // 3. 兼容部分：
                // 老的浏览器可能根本没有实现 mediaDevices，所以我们可以先设置一个空的对象
                if (navigator.mediaDevices === undefined) {
                    navigator.mediaDevices = {};
                }
                // 一些浏览器部分支持 mediaDevices。我们不能直接给对象设置 getUserMedia
                // 因为这样可能会覆盖已有的属性。这里我们只会在没有getUserMedia属性的时候添加它。
                if (navigator.mediaDevices.getUserMedia === undefined) {
                    navigator.mediaDevices.getUserMedia = function(constraints) {
                    // 首先，如果有getUserMedia的话，就获得它
                    var getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia || navigator.oGetUserMedia;
                    // 一些浏览器根本没实现它 - 那么就返回一个error到promise的reject来保持一个统一的接口
                    if (!getUserMedia) {
                        return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
                    }
                    // 否则，为老的navigator.getUserMedia方法包裹一个Promise
                    return new Promise(function(resolve, reject) {
                        getUserMedia.call(navigator, constraints, resolve, reject);
                    });
                  }
                }
                // 4. 获取视频流
                let that=this
                navigator.mediaDevices.getUserMedia(constraints)
                .then(function(stream) {
                    // 进来这里表示能够兼容
                    let video=document.querySelector('video');
                    video.srcObject = stream;
                    video.onloadedmetadata = function() {
                      video.play();
                    };
                    // 进入自定义拍摄模式
                    that.status=1 
                }).catch(function(err) {
                     // 进来这里表示不能兼容
                    console.log('nonono',err)
                    // 调用原始摄像头
                    that.originCamera()
                }); 
            } catch (error) {
                alert(error);
            }
        },
        originCamera(){
            let that=this
            //关闭自定义相机
            that.cameraShow=false
            var ss = this.$refs.camerafile;
            console.log(ss.files);
            let file=document.getElementById('file')
            file.click()
          },
        snapPhoto(){
            try {
                var canvas = document.querySelector('#mycanvas');
                var video = document.querySelector('video');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                canvas.getContext('2d').drawImage(video,0,0);
                // 保存为文件，用于后续的上传至服务器（尚未实践）——>后续提交
                this.imageFile=this.canvasToFile(canvas)
                this.imgBase64Data = canvas.toDataURL('image/jpeg');
                // blob转url：用于展示
                // eslint-disable-next-line no-unused-vars
                let p=new Promise((resolve,reject)=>{
                    canvas.toBlob(blob=>{
                        let url=URL.createObjectURL(blob)
                        resolve(url)
                    });
                })
                let that=this
                p.then(value=>{
                    that.imageUrl=value
                    that.status=2//表示拍摄完成
                    that.CameraVisable = false;
                })
            } catch (error) {
                alert(error);
            }
        },
        fileOnChange(){
          var ss = this.$refs.camerafile;
          this.fileToBlob(ss.files[0]);
        },
        photoChange(){
          var ss = this.$refs.photoFile;
          this.fileToBlob(ss.files[0]);
        },
        fileToBlob(file){
            console.log(file);
            var reader = new FileReader();
            var that = this;
            reader.onload = function() {
                var img = new Image();
                img.src = reader.result;
                img.onload = function() {
                    var w = img.width,
                        h = img.height;
                    var canvas = document.createElement('canvas');
                    var ctx = canvas.getContext('2d');
                    canvas.width=w;
                    canvas.height=h;
                    ctx.drawImage(img, 0, 0, w, h);
                    var base64 = canvas.toDataURL('image/jpeg', 0.5);
                    var result = {
                        url: window.URL.createObjectURL(file),
                        base64: base64,
                        clearBase64: base64.substr(base64.indexOf(',') + 1),
                        suffix: base64.substring(base64.indexOf('/') + 1, base64.indexOf(';')),
                    };
                    console.log('result',result);
                    that.submitPhoto('origin',result.base64);
                }
            }
            reader.readAsDataURL(file);
        },
        canvasToFile(canvas){
            var dataurl = canvas.toDataURL("image/png");
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            var file = new File([u8arr], "phone.png", {type: mime});
            return file
        },
        submitPhoto(type,file) {
          if(type=='origin'){
              this.imgBase64Data=file
          }
          //console.log("提交",this.imageFile);
          this.cameraShow=false
          this.$emit("photoSubmit",this.imgBase64Data);
        },
        cameraCancel(){
          this.$emit('cancel');
        }
    },
    mounted(){
      // this.vConsole = new VConsole()
      console.log("useragent",navigator.userAgent);
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        console.log("原始相机");
        this.originCamera();
      }else{
        this.openCamera();
      }
    },
    created(){
    },
    destroyed(){
    }
})
</script>
<style scoped>
.mask{
  width: 342px;
  height: 240px;
  opacity: 0.8;
  margin-top: 20vh;
  /* transform: translate(-50%, 0);  */
}
  .img{
    width:100%;
    height:100%
  }
  
  .bigger{
    font-weight: 600;
    font-size: 3em;
  }
  .small{
    font-size: 2em;
  }
  .smaller{
    font-size: 1.2em;
  }
  .control{
    width: 100%;
    position: fixed; 
    left: 0; 
    bottom: 0; 
    top: 75vh; 
    right: 0;
    /* background:black; */
  }
  .control_before{
      position:relative;
      width:100%;
      height:100%;
      display: flex;
      flex-direction: column;
      background-color: black;
    }
  .control_before_top{
        z-index: 1;
        flex: 1;
        font-size: 18px;
        display: flex;
        color: white;
        justify-content: center;
        align-items: center;
      }
      .control_before_top font{
        color: red;
      }
      .control_before_bottom{
        flex: 2;
        display: flex;
        justify-content: space-around;
        color: white;
        align-items: center;
        margin-bottom: 1.5em;
      }
  .control_after{
      position:relative;
      width:100%;
      height:100%;
      display: flex;
      color: white;
      align-items: center;
      justify-content: space-around;
      background-color: black;
    }
  main{
    text-align: center;
  }
  .tipinfo{
    z-index: 2;
    position: fixed;
    top:46px;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .info{
      margin: 1em 0 3em;
    }
   .van-icon{
        color: #f68618;
        font-size: 5em;
      }
      .title{
        color: #f68618;
        font-size: 1.8em;
      } 
   .van-icon{
        color: red;
        font-size: 5em;
      }
      .title{
        color: red;
        font-size: 1.8em;
      } 
  .btn{
    height: 34px;
    width: 80vw;
    background: #f68618;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .3em;
    margin: 0 auto;
  }
</style>