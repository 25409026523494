import { render, staticRenderFns } from "./plateNumber.vue?vue&type=template&id=03a8ed46&scoped=true&"
import script from "./plateNumber.vue?vue&type=script&lang=js&"
export * from "./plateNumber.vue?vue&type=script&lang=js&"
import style0 from "./plateNumber.vue?vue&type=style&index=0&id=03a8ed46&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03a8ed46",
  null
  
)

export default component.exports