<template>
  <div id="page">
    <div class="wrap">
      <div class="radio-box">
        <button type="button" @click="formData().commonCard=1;btn1=true;btn2=false;" :class="{'btn':true,'btn-outline-secondary':true,'type_btn':true,'btn_click_after2':formData().commonCard==1}">普通车牌</button>
        <button type="button" @click="formData().commonCard=2;btn1=false;btn2=true;" :class="{'btn':true,'btn-outline-secondary':true,'type_btn':true,'btn_click_after2':formData().commonCard==2}">新能源车牌</button>
      </div>
      <div class="num-box" v-for="(item,index) in arr" :key="index">
        <div class="num0" @click="inputIndex=index;clickFirstWrap()">
            <span>{{item.num0}}<div class="arrow_ down"> </div></span>
        </div>
        <div class="num1" @click="inputIndex=index;clickKeyWordWrap(1);"><span>{{item.num1}}</span></div>
        <em class="spot"></em>
        <div class="num1" @click="inputIndex=index;clickKeyWordWrap(2)"><span>{{item.num2}}</span></div>
        <div class="num1" @click="inputIndex=index;clickKeyWordWrap(3)"><span>{{item.num3}}</span></div>
        <div class="num1" @click="inputIndex=index;clickKeyWordWrap(4)"><span>{{item.num4}}</span></div>
        <div class="num1" @click="inputIndex=index;clickKeyWordWrap(5)"><span>{{item.num5}}</span></div>
        <div class="num1" @click="inputIndex=index;clickKeyWordWrap(6)"><span>{{item.num6}}</span></div>
        <div v-if="item.commonCard == '2'" class="num1" @click="inputIndex=index;clickKeyWordWrap(7)"><span>{{item.num7}}</span></div>
        <!-- <van-button native-type="button" icon="minus"  /> -->
        <van-icon name="minus" v-if="arr.length>1" color="red" style="margin-left:4px" @click="inputIndex=index;minus(index)" />
      </div>
      <div class="add-box" v-if="showAdd">
          <!-- <van-icon name="plus" :size="30" /> -->
          <van-button native-type="button" @click="add" icon="plus" block />
      </div>
    </div>
    <div class="first-word-wrap"
      v-if="firstWrapStatus&&!keyBoardStatus">
      <div class="first-word"
        @click="selectFirstWord($event)">
        <div class="word">
          <span>京</span>
        </div>
        <div class="word">
          <span>湘</span>
        </div>
        <div class="word">
          <span>津</span>
        </div>
        <div class="word">
          <span>鄂</span>
        </div>
        <div class="word">
          <span>沪</span>
        </div>
        <div class="word">
          <span>粤</span>
        </div>
        <div class="word">
          <span>渝</span>
        </div>
        <div class="word">
          <span>琼</span>
        </div>
      </div>
      <div class="first-word"
        @click="selectFirstWord($event)">
        <div class="word">
          <span>冀</span>
        </div>
        <div class="word">
          <span>川</span>
        </div>
        <div class="word">
          <span>晋</span>
        </div>
        <div class="word">
          <span>贵</span>
        </div>
        <div class="word">
          <span>辽</span>
        </div>
        <div class="word">
          <span>云</span>
        </div>
        <div class="word">
          <span>吉</span>
        </div>
        <div class="word">
          <span>陕</span>
        </div>
      </div>
      <div class="first-word"
        @click="selectFirstWord($event)">
        <div class="word">
          <span>黑</span>
        </div>
        <div class="word">
          <span>甘</span>
        </div>
        <div class="word">
          <span>苏</span>
        </div>
        <div class="word">
          <span>青</span>
        </div>
        <div class="word">
          <span>浙</span>
        </div>
        <div class="word">
          <span>皖</span>
        </div>
        <div class="word">
          <span>藏</span>
        </div>
        <div class="word">
          <span>闽</span>
        </div>
      </div>
      <div class="first-word"
        @click="selectFirstWord($event)">
        <div class="word">
          <span>蒙</span>
        </div>
        <div class="word">
          <span>赣</span>
        </div>
        <div class="word">
          <span>桂</span>
        </div>
        <div class="word">
          <span>鲁</span>
        </div>
        <div class="word">
          <span>宁</span>
        </div>
        <div class="word">
          <span>豫</span>
        </div>
        <div class="word">
          <span>新</span>
        </div>
      </div>
      <div class="first-word">
        <div class="word" style="width:4rem;position: absolute;right:10px;">
          <span @click="firstWrapStatus=false;" style="background-color: #465266;color: white;">完成</span>
        </div>
      </div>
    </div>
    <div class="keyboard-wrap" v-if="keyBoardStatus === true">
      <!-- <div class="number-wrap"></div>
      <div class="letter-wrap"></div>
      <div class="cn-wrap"></div> -->
      <div class="keyboard" v-if="activeKeyWordIndex !== 1">
        <span v-for="(item,index) in allKeyWord._1"
          :key="index"
          @click="clickKeyBoard(item)">{{item}}</span>
      </div>
      
      <div class="keyboard">
        <span v-for="(item,index) in allKeyWord._3"
          :key="index"
          @click="clickKeyBoard(item)">{{item}}</span>
      </div>
      <div class="keyboard">
        <span v-for="(item,index) in allKeyWord._4"
          :key="index"
          @click="clickKeyBoard(item)">{{item}}</span>
      </div>
      <div class="keyboard">
        <span v-for="(item,index) in allKeyWord._5"
          :key="index"
          @click="clickKeyBoard(item)">{{item}}</span>
      </div>
      <div class="keyboard">
        <span v-for="(item,index) in allKeyWord._6"
          :key="index"
          @click="clickKeyBoard(item)">{{item}}</span>
          <span class="bordernone"></span>
          <span class="bordernone"></span>
          <span class="bordernone"></span>
          <!-- <span class="bordernone" v-if="activeKeyWordIndex === 1"></span>
          <span class="bordernone" v-if="activeKeyWordIndex === 1"></span> -->
          <!-- <span @click="deleteWord" v-if="activeKeyWordIndex === 1">x</span> -->
      </div>
      <div class="keyboard" >
        <span v-for="(item,index) in allKeyWord._7"
          :key="index"
          @click="clickKeyBoard(item)">{{item}}</span>
          <span class="bordernone"></span>
          <span class="delete" @click="deleteWord"><img src="../assets/image/icon-delete.png" alt=""></span>
      </div>
      <div class="cancel">
        <span @click="keyBoardStatus = false">完成</span>
      </div>
    </div>
  </div>
</template>
<script>
import {Icon,Button} from 'vant'
import util from '../helper/util'
// import '../assets/css/bootstrap.min.css'
// import '../assets/js/bootstrap.min.js'
export default {
  components:{
    [Icon.name]: Icon,
    [Button.name]:Button
  },
  props:{
    showAdd:{
      type:Boolean,
      default:false
    }
  },
  data () {
    return {
      thatUtil:{},
      inputIndex:0,
      arr:[{
        commonCard: '1',
        num0: '京',
        num1: '',
        num2: '',
        num3: '',
        num4: '',
        num5: '',
        num6: '',
        num7: ''
      }],
      btn1:true,
      btn2:false,
      // formData: {
      //   commonCard: '1',
      //   num0: '京',
      //   num1: '',
      //   num2: '',
      //   num3: '',
      //   num4: '',
      //   num5: '',
      //   num6: '',
      //   num7: ''
      // },
      allKeyWord: {
        _1: [1, 2, 3, 4, 5, 6, 7,8, 9, 0],
        _3: ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
        _4: ['H', 'J', 'K', 'L', 'M', 'N', 'O'],
        _5: ['P', 'Q', 'R', 'S', 'T', 'U', 'V'],
        _6: ['W', 'X', 'Y', 'Z'],
        _7: ['港', '澳', '学', '领', '警']
      },
      activeKeyWordIndex: 1, // 当前车牌号
      keyBoardStatus: false,
      firstWrapStatus: false, // 选择弹窗
      confirmTitle: '',
      submitConfirm: false,
      submitConfirmFalse: false,
      submitConfirmText: ''
    }
  },
  mounted () {
  },
  methods: {
    minus(index){
      if(index==(this.arr.length-1)){
        this.arr.splice(index,1);
        this.inputIndex = this.arr.length-1;
      }else{
        this.arr.splice(index,1);
      }
      var res = this.arr.map(a=>a.num0+a.num1+a.num2+a.num3+a.num4+a.num5+a.num6+a.num7)
      this.$emit('getPlateLicense',res)
    },
    formData(){
      return this.arr[this.inputIndex]
    },
    add(){
      this.arr.push({
        commonCard: '1',
        num0: '京',
        num1: '',
        num2: '',
        num3: '',
        num4: '',
        num5: '',
        num6: '',
        num7: ''
      })
      
    },
    clear(){
      
      this.inputIndex=0;
      this.arr = [{commonCard: '1',
                        num0: '京',
                        num1: '',
                        num2: '',
                        num3: '',
                        num4: '',
                        num5: '',
                        num6: '',
                        num7: ''}];
    },
    clickFirstWrap () {
      // 点击第一个输入框
      this.firstClickStatus = true
      this.firstWrapStatus = true
      this.keyBoardStatus = false
      this.formData().num0 = ''
    },
    selectFirstWord (event) {
      // 选择省份
      if (event.target.localName !== 'span') {
        return
      }
      this.formData().num0 = event.target.innerText
      this.firstSelectStatus = true
      this.firstWrapStatus = false
      this.firstClickStatus = false
      this.keyBoardStatus = true
      this.activeKeyWordIndex = 1
      this.submitFn();
      // this.$refs.num1.focus()
      // document.getElementById('num1').focus()
    },
    clickKeyBoard (item) { // 点击自定义键盘
      console.log(item)
      this.formData()['num' + this.activeKeyWordIndex] = item
      this.submitFn();
      // console.log("this.commonCard");
      // console.log(this.formData.commonCard);
      // console.log("this.activeKeyWordIndex");
      // console.log(this.activeKeyWordIndex);
      if (this.formData().commonCard == '1') {
        this.activeKeyWordIndex++
        if (this.activeKeyWordIndex > 6) {
          this.keyBoardStatus = false
        }
      } else {
        this.activeKeyWordIndex++
        if (this.activeKeyWordIndex > 7) {
          this.keyBoardStatus = false
        }
      }
    },
    deleteWord () { // 退格
      // console.log(this.activeKeyWordIndex)
      // console.log(this.formData['num' + (this.activeKeyWordIndex - 1)])
      if (this.activeKeyWordIndex > 1) {
        this.formData()['num' + (this.activeKeyWordIndex - 1)] = ''
        this.activeKeyWordIndex--
      }
    },
    clickKeyWordWrap (activeKeyWordIndex) {
      console.log(activeKeyWordIndex);
      this.keyBoardStatus = true
      this.activeKeyWordIndex = activeKeyWordIndex
      this.formData()['num' + this.activeKeyWordIndex] = '';
      this.changeSecondHeight();
    },
    submitFn () {
      let plateLicense;
      console.log(this.formData().commonCard );
      if (this.formData().commonCard == '1') {
        plateLicense = this.plate_license_1
        plateLicense = this.palindrome(plateLicense)
        // if (plateLicense.length < 7) {
        //   alert('请输入正确的车牌号')
        //   return
        // }
      }
      if (this.formData().commonCard == '2') {
        plateLicense = this.plate_license_2
        plateLicense = this.palindrome(plateLicense)
        // if (plateLicense.length < 8) {
        //   alert('请输入正确的车牌号')
        //   return
        // }
      }
      var res = this.arr.map(a=>a.num0+a.num1+a.num2+a.num3+a.num4+a.num5+a.num6+a.num7)
      this.$emit('getPlateLicense',res)
        console.log(plateLicense);
      // alert(plateLicense)
    },
    palindrome (str) {
      var arr = str.split('')
      arr = arr.filter(function (val) {
        return (
          val !== ' ' &&
          val !== ',' &&
          val !== '.' &&
          val !== '?' &&
          val !== ':' &&
          val !== ';' &&
          val !== '`' &&
          val !== "'" &&
          val !== '_' &&
          val !== '/' &&
          val !== '-' &&
          val !== '\\' &&
          val !== '' &&
          val !== '(' &&
          val !== ')'
        )
      })
      return arr.join('')
    },
    checkIsHasSpecialStr (str) {
      var flag = false
      var arr = str.split('')
      arr.forEach(val => {
        if (
          val === '!' ||
          val === '}' ||
          val === '{' ||
          val === ']' ||
          val === '[' ||
          val === '&' ||
          val === '$' ||
          val === '@' ||
          val === ' ' ||
          val === ',' ||
          val === '.' ||
          val === '?' ||
          val === ':' ||
          val === ';' ||
          val === '`' ||
          val === "'" ||
          val === '_' ||
          val === '/' ||
          val === '-' ||
          val === '\\' ||
          val === '' ||
          val === '(' ||
          val === ')'
        ) {
          flag = true
        }
      })
      return flag
    },
    checkIsHasChineseStr (str) {
      var Reg = /.*[\u4e00-\u9fa5]+.*/
      if (Reg.test(str)) {
        return true
      }
      return false
    },
    changeFirstHeight(){
      this.$nextTick(function(){
        var isHW =this.thatUtil.judgeBrand(navigator.userAgent.toLowerCase());
        var height = window.innerHeight;
        document.querySelector('.first-word-wrap').style.top ='70vh';
        var keyHeight = document.querySelector('.first-word-wrap').offsetHeight;
        var top = '';
        if(isHW){
          top =(height-keyHeight-95)+'px';
        }else{
          top = (height - keyHeight)+'px';
        }
        console.log('firstWrapStatus',top);
        document.querySelector('.first-word-wrap').style.top =top;
      });
    },
    changeSecondHeight(){
       this.$nextTick(function(){
          var isHW =this.thatUtil.judgeBrand(navigator.userAgent.toLowerCase());
          // document.querySelector('.keyboard-wrap').style.height= '18rem';
          document.querySelector('.keyboard-wrap').style.top ='62vh';
          var height = window.innerHeight;
          var keyHeight = document.querySelector('.keyboard-wrap').offsetHeight;
          var top = '';
          if(isHW){
            top =(height-keyHeight-95)+'px';
          }else{
            top = (height - keyHeight)+'px';
          }
          document.querySelector('.keyboard-wrap').style.top =top ;
      });
    },
    setPlateNumber(number){
      if(number.length>7){
        this.formData().commonCard = 2
      }
      for(var i in number){
        this.formData()['num'+i] = number[i]
      }
    }
  },
  computed: {
    plate_license_1 () {
      return (
        this.formData().num0 +
        this.formData().num1 +
        this.formData().num2 +
        this.formData().num3 +
        this.formData().num4 +
        this.formData().num5 +
        this.formData().num6
      )
    },
    plate_license_2 () {
      return (
        this.formData().num0 +
        this.formData().num1 +
        this.formData().num2 +
        this.formData().num3 +
        this.formData().num4 +
        this.formData().num5 +
        this.formData().num6 +
        this.formData().num7
      )
    },
  },
  watch:{
    firstWrapStatus(val){    
        if(val){
          console.log('changeFirstHeight');
          this.changeFirstHeight();
        }else{
          this.changeSecondHeight();
        }
    }   
  },
  created(){
    this.thatUtil = util;
  }
}
</script>
<style lang="less" scoped>
.add-box{
  // height: 34px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  text-align: center;
  // line-height: 34px;
  // padding: 5px 0px;
  border: 1px solid #b9b9b9;
}
.arrow_{
  width:1px;
  height:1px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent ;
  border-top: 4px solid #fff;
}
.btn{
  height: 35px;
  font-size: 14px;
  background-color: #fff;
  color: #4600af;
  border: 1px #4600af solid;
  border-radius: 5px;  
}
.btn_click_after2 {
    background: #4600AF;
    color: white;
}
.flex-items-center {
  display: flex;
  align-items: center;
}
.type_btn{
    width: 39%;
    margin-bottom: 3%;
    margin-right:7%;
}
.type_btn:first-child{
  margin-right:7%;
}
.wrap {
  padding: 0.85rem 0rem;
  background-color: #fff;
  border-radius: 0.5rem;
  .radio-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 0.7rem;
    text-align: right;
    color: #4a4a4a;
    input[type="radio"] {
      display: none;
    }
    label {
      padding-left: 0.6rem;
      cursor: pointer;
      img {
        width: 0.8rem;
        margin-right: 0.1rem;
      }
    }
  }
  .card-header {
    font-size: 0.75rem;
    margin: 0.2rem 0 0.5rem;
    color: #4a4a4a;
  }
  // .num-box {}
  // input输入框
  .num-box {
    margin-top: 5px;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    margin-left:0.3rem;
    .spot {
      width: 5px;
      height: 5px;
      background: #703bc1;
      border-radius: 50%;
      margin-left:0.4rem;
    }
    
    & > div {
      width: 34px;
      height: 34px;
      border: 1px solid #e4e4e4;
      background: #fff;
      color: #828282;
      border-radius: 5px;
      margin-left:0.4rem;
      &.first {
        position: relative;
        text-align: center;
        line-height: 1.7rem;
        font-weight: 200;
        margin-left:20px;
        .input-wrap {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          &.active {
            z-index: 100;
          }
        }
        em {
          color: #979797;
          font-size: 1.6rem;
          line-height: 1.7rem;
        }
        span {
          display: inline-block;
          width: 100%;
          height: 100%;
          // background-color: #9cbce2;
          color: #828282;
          line-height: 1.8rem;
        }
      }
      &.active {
        border: 1px solid #4a90e2;
        &:after {
          border-bottom: 0.5rem solid #4a90e2;
        }
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: 1rem;
        width: 34px;
        height: 34px;
        // background: #fff;
        border-radius: 5px;
        // color: #828282;
        &.first {
          // background-color: #9cbce2;
          color: #fff;
          text-indent: 0.4rem;
          border-radius: 0;
        }
      }
    }
  }
  .num-box div:first-child{
    background: #4600af;
    color: #fff;
  }
  .submit-box {
    button {
      width: 100%;
      height: 2.2rem;
      border-radius: 0.25rem;
      font-size: 0.75rem;
      margin-top: 0.7rem;
      background: linear-gradient(
        320deg,
        rgba(74, 144, 226, 1) 0%,
        rgba(101, 172, 248, 1) 100%
      );
      color: #fff;
    }
  }
  .info {
    font-size: 0.5rem;
    margin-top: 0.9rem;
    color: #828282;
    text-align: left;
    img {
      width: 0.6rem;
      vertical-align: middle;
    }
  }
}
.first-word-wrap {
  // height: 9.4rem;
  z-index: 100;
  background-color: #D2D5DB;
  padding: 0.6rem 0.8rem 1.1rem;
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  .first-word {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.45rem;
    .word {
      box-sizing: border-box;
      width: 1.8rem;
      height: 1.8rem;
      // border: 1px solid #9cbce2;
      box-shadow: 0px 1px 4px  rgba(0, 0, 0, 0.35);
      border-radius: 0.16rem;
      text-align: center;
      &.bordernone {
        border: none;
        box-shadow:none
      }
      span {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        height: 100%;
        background-color: #fff;
        color: #000;
        // border: 1px solid #fff;
        border-radius: 0.3rem;
      }
      img {
        width: 1.6rem;
      }
    }
    &:nth-last-of-type(1){
      margin-bottom: 0rem;
    }
  }
}
.keyboard-wrap {
  z-index: 100;
  background-color: #D2D5DB;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.6rem 0.6rem 0.4rem;
  .keyboard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2rem;
    margin-bottom: 0.3rem;
    span {
      text-align: center;
      display: flex;
      width: 2rem;
      align-items: center;
      justify-content: center;
      height: 1.8rem;
      margin: 0 0.1rem;
      box-shadow: 0px 1px 4px  rgba(0, 0, 0, 0.35);
      background-color: #fff;
      border-radius: 0.3rem;
      &:active {
        background-color: #e4e4e4;
      }
      &.bordernone{
        border: none;
        box-shadow: none;
        background-color: #D2D5DB;
        &:active{
          background-color:#D2D5DB;
        }
      }
      &.delete{
        background-color: #465266;
        img{
          width: 1.15rem;
        }
      }
    }
  }
  .cancel{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    span{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.6rem;
      height: 1.8rem;
      background-color: #465266;
      color: #fff;
      border-radius: 0.125rem;
    }
  }
}
</style>
